<template>
  <v-layout>
    <v-main>
      <Header />
      <h1>WEBSITE UNDER CONSTRUCTION</h1>
    </v-main>
  </v-layout>
</template>

<script>
import Header from "@/components/header.vue";
export default {
  name: "HomeView",

  components: {
    Header,
  },
  data() {
    return {
      order: 0,
      secretMessage: "",
      email: "",
    };
  },
  async created() {
    // if (!this.$store.getters.isLoggedIn) {
    //   this.$router.push('/login')
    // }
    // this.username = this.$store.getters.getUser.username
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
};
</script>
