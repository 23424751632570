<template>
  <v-card>
    <div class="d-flex justify-center align-center w-100">
      <v-app-bar color="primary">
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title>GlobalDreamz Edutech</v-toolbar-title>
      </v-app-bar>
      <Sidebar :drawer="drawer" @sidebarHover="sidebarHover" />
    </div>
  </v-card>
</template>
<script>
import Sidebar from "@/components/sidebar.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  components: { Sidebar },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    sidebarHover(value) {
      this.drawer = value;
    },
  },
};
</script>
