import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "loginView",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/contactus",
    name: "ContactUs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactUs.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignUp.vue"),
  },
  {
    path: "/resetpassword",
    name: "Reset password",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResetView.vue"),
  },
  {
    path: "/thank-you",
    name: "TQView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TQView.vue"),
  },
  {
    path: "/career-selection",
    name: "Career Selection",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/careerselection.vue"),
  },
  {
    path: "/sop",
    name: "Statement Of Purpose",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SOPView.vue"),
  },
  {
    path: "/recommendation",
    name: "Letter Of Recommendation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LORView.vue"),
  },
  {
    path: "/resume",
    name: "Resume",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResumeView.vue"),
  },
  {
    path: "/loan",
    name: "Loan Assistance",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoanView.vue"),
  },
  {
    path: "/finance",
    name: "Financial Planning",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FinanceView.vue"),
  },
  {
    path: "/visa",
    name: "VISA Applications",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VISAView.vue"),
  },
  {
    path: "/admits",
    name: "Admit Letters",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdmitView.vue"),
  },
  {
    path: "/education-documents",
    name: "Education Documents",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EdDocView.vue"),
  },
  {
    path: "/scholarships",
    name: "Scholarships",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scholarships.vue"),
  },
  {
    path: "/interviews",
    name: "University Interviews",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UniIntView.vue"),
  },
  {
    path: "/chat/soplor",
    name: "SOP and LOR",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SOPLORchat.vue"),
  },
  {
    path: "/chat/coordinator",
    name: "Student Coordinator",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/coordinatorchat.vue"),
  },
  {
    path: "/chat/Finance",
    name: "Financial Assistance Chat",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FinanceChat.vue"),
  },
  {
    path: "/chat/VISA",
    name: "VISA Assistance Chat",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VISAchat.vue"),
  },
];

const openPaths = [
  "loginView",
  "Reset password",
  "signup",
  "ContactUs",
  "TQView",
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function authCheck() {
  return store.getters.loginState;
}

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from) => {
  if (to.name === "loginView" && localStorage.getItem("vuex") === null)
    return true;
  if (localStorage.getItem("vuex") !== null) {
    await store.restored;
    console.log(store.getters.decodedToken.exp);
    if (
      parseInt(store.getters.decodedToken.exp) <
      parseInt(new Date().getTime() / 1000)
    ) {
      await localStorage.removeItem("vuex");
      console.log("Token Expired");
      return { name: "loginView" };
    }
  }
  if (!openPaths.includes(to.name))
    return authCheck() ? true : { name: "loginView" };
  console.log("Not Protected");
  return true;
});
export default router;
