<template>
  <v-card
    @mouseover="this.$emit('sidebarHover', true)"
    @mouseleave="this.$emit('sidebarHover', false)"
  >
    <div v-if="!this.$vuetify.display.mobile">
      <v-navigation-drawer :rail="!drawer">
        <v-list>
          <v-list-group :value="drawer">
            <template v-slot:activator="{ props }">
              <v-list-item
                prepend-icon="mdi_forum"
                v-bind="props"
                title="Chat"
                nav
                density="compact"
                style="padding-left: 5px"
              ></v-list-item>
            </template>
            <div v-if="drawer">
              <v-list-item
                nav
                density="compact"
                prepend-icon="mdi-forum"
                title="Coordinator Chat"
                style="font-size: small"
                @click="$router.push('/chat/coordinator')"
              >
              </v-list-item>
              <v-list-item
                nav
                density="compact"
                prepend-icon="mdi-forum"
                title=" SOP and LOR chat"
                style="font-size: small"
                @click="$router.push('/chat/soplor')"
              >
              </v-list-item>
              <v-list-item
                nav
                density="compact"
                prepend-icon="mdi-forum"
                title="VISA assistance chat"
                style="font-size: small"
                @click="$router.push('/chat/visa')"
              >
              </v-list-item>
              <v-list-item
                nav
                density="compact"
                prepend-icon="mdi-forum"
                title="Financial assistance chat"
                style="font-size: small"
                @click="$router.push('/chat/finance')"
              >
              </v-list-item>
            </div>
          </v-list-group>
          <!--          <v-list-group :value="drawer">-->
          <!--            <template v-slot:activator="{ props }">-->
          <!--              <v-list-item-->
          <!--                prepend-icon="mdi_forum"-->
          <!--                v-bind="props"-->
          <!--                title="Career Selection"-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                style="padding-left: 5px"-->
          <!--              ></v-list-item>-->
          <!--            </template>-->
          <!--            <div v-if="drawer">-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Questionaire"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="SWOT analysis"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Career Recommendation"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Mentor Connect"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Resources"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--            </div>-->
          <!--          </v-list-group>-->
          <!--          <v-list-group :value="drawer">-->
          <!--            <template v-slot:activator="{ props }">-->
          <!--              <v-list-item-->
          <!--                prepend-icon="mdi_forum"-->
          <!--                v-bind="props"-->
          <!--                title="Education Documents"-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                style="padding-left: 5px"-->
          <!--              ></v-list-item>-->
          <!--            </template>-->
          <!--            <div v-if="drawer">-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Coordinator Chat"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--            </div>-->
          <!--          </v-list-group>-->
          <!--          <v-list-group :value="drawer">-->
          <!--            <template v-slot:activator="{ props }">-->
          <!--              <v-list-item-->
          <!--                prepend-icon="mdi_forum"-->
          <!--                v-bind="props"-->
          <!--                title="Statement of Purpose"-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                style="padding-left: 5px"-->
          <!--              ></v-list-item>-->
          <!--            </template>-->
          <!--            <div v-if="drawer">-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Coordinator Chat"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--            </div>-->
          <!--          </v-list-group>-->
          <!--          <v-list-group :value="drawer">-->
          <!--            <template v-slot:activator="{ props }">-->
          <!--              <v-list-item-->
          <!--                prepend-icon="mdi_forum"-->
          <!--                v-bind="props"-->
          <!--                title="Recommendation Letters"-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                style="padding-left: 5px"-->
          <!--              ></v-list-item>-->
          <!--            </template>-->
          <!--            <div v-if="drawer">-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Coordinator Chat"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--            </div>-->
          <!--          </v-list-group>-->
          <!--          <v-list-group :value="drawer">-->
          <!--            <template v-slot:activator="{ props }">-->
          <!--              <v-list-item-->
          <!--                prepend-icon="mdi_forum"-->
          <!--                v-bind="props"-->
          <!--                title="Loan Assistance"-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                style="padding-left: 5px"-->
          <!--              ></v-list-item>-->
          <!--            </template>-->
          <!--            <div v-if="drawer">-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Coordinator Chat"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--            </div>-->
          <!--          </v-list-group>-->
          <!--          <v-list-group :value="drawer">-->
          <!--            <template v-slot:activator="{ props }">-->
          <!--              <v-list-item-->
          <!--                prepend-icon="mdi_forum"-->
          <!--                v-bind="props"-->
          <!--                title="Financial Assistance"-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                style="padding-left: 5px"-->
          <!--              ></v-list-item>-->
          <!--            </template>-->
          <!--            <div v-if="drawer">-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Coordinator Chat"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--            </div>-->
          <!--          </v-list-group>-->
          <!--          <v-list-group :value="drawer">-->
          <!--            <template v-slot:activator="{ props }">-->
          <!--              <v-list-item-->
          <!--                prepend-icon="mdi_forum"-->
          <!--                v-bind="props"-->
          <!--                title="VISA Application help"-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                style="padding-left: 5px"-->
          <!--              ></v-list-item>-->
          <!--            </template>-->
          <!--            <div v-if="drawer">-->
          <!--              <v-list-item-->
          <!--                nav-->
          <!--                density="compact"-->
          <!--                prepend-icon="mdi-forum"-->
          <!--                title="Coordinator Chat"-->
          <!--                style="font-size: small"-->
          <!--                @click="$router.push('/chat/coordinator')"-->
          <!--              >-->
          <!--              </v-list-item>-->
          <!--            </div>-->
          <!--          </v-list-group>-->
        </v-list>
      </v-navigation-drawer>
    </div>
    <div v-else>
      <v-navigation-drawer v-model="$props.drawer" temporary location="bottom">
        <v-list>
          <v-list-group :value="drawer">
            <template v-slot:activator="{ props }">
              <v-list-item
                prepend-icon="mdi_forum"
                v-bind="props"
                title="Chat"
                nav
                density="compact"
                style="padding-left: 5px"
              ></v-list-item>
            </template>
            <div v-if="drawer">
              <v-list-item
                nav
                density="compact"
                prepend-icon="mdi-forum"
                title="Coordinator Chat"
                style="font-size: small"
                @click="$router.push('/chat/coordinator')"
              >
              </v-list-item>
              <v-list-item
                nav
                density="compact"
                prepend-icon="mdi-forum"
                title=" SOP and LOR chat"
                style="font-size: small"
                @click="$router.push('/chat/soplor')"
              >
              </v-list-item>
              <v-list-item
                nav
                density="compact"
                prepend-icon="mdi-forum"
                title="VISA assistance chat"
                style="font-size: small"
                @click="$router.push('/chat/visa')"
              >
              </v-list-item>
              <v-list-item
                nav
                density="compact"
                prepend-icon="mdi-forum"
                title="Financial assistance chat"
                style="font-size: small"
                @click="$router.push('/chat/finance')"
              >
              </v-list-item>
            </div>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "SidebarComp",
  data: () => {
    return {};
  },
  props: ["drawer"],
};
</script>
