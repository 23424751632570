import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import jwt_decode from "jwt-decode";
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
const userAuthenticationModule = {
  state: () => ({
    loginCheck: false,
    sessiontoken: "",
    decode: null,
  }),
  getters: {
    loginState: (state) => {
      return state.loginCheck;
    },
    rawSessionToken: (state) => {
      return state.sessiontoken;
    },
    decodedToken: (state) => {
      return state.decode;
    },
  },
  mutations: {
    updateLoginState(state, payload) {
      state.loginCheck = payload.message;
    },
    updateSessionToken(state, payload) {
      state.sessiontoken = payload.message;
      state.decode = jwt_decode(payload.message);
    },
  },
  actions: {
    updateLoginState(context, payload) {
      context.commit("updateLoginState", payload);
    },
    updateSessionToken(context, payload) {
      context.commit("updateSessionToken", payload);
    },
  },
};

export default createStore({
  modules: {
    authModule: userAuthenticationModule,
  },
  plugins: [vuexLocal.plugin],
});
